import FlexBox from "components/layout/FlexBox"
import NavRow from "components/layout/NavRow"
import fileTypes from "images/fileTypes.png"
import quality from "images/quality.png"
import {Redirect} from "react-router-dom"
import UploadFiles from "components/UploadFiles"
import {Routes} from "MobileApp"
import {useAppContext} from "api/app/AppContext"
import Loading from "components/Loading"
import Button from "components/Button"
import {useTheme} from "hooks/useTheme"

const IncomePayslipsUpload = () => {
  const {appState, uploadPayslip, onTryAgainClicked} = useAppContext()
  const {token, incomeState} = appState || {}
  const {uploadPayslipLoading, uploadPayslipCompleted, uploadPayslipFailed} = incomeState || {}
  const theme = useTheme()

  if (uploadPayslipLoading) {
    return (
      <FlexBox flexGrow column>
        {/*<NavRow number={1} text={"Photo of ID Card/Book"} selected={true} complete={true}/>*/}
        {/*<NavRow number={2} text={"Selfie without smiling"} selected={true} complete={true}/>*/}
        {/*<NavRow number={3} text={"Selfie with a smile"} selected={true} complete={true}/>*/}
        <NavRow number={1} text={"Bank Statement or Payslip"} selected={true} last={true}/>
        <FlexBox style={{backgroundColor: "#eeeff0"}} centered>
          <FlexBox style={{backgroundColor: "#ffffff", paddingTop: 20}} padding margin column>
            <div style={{paddingTop: 10}}>
              PAYSLIP SUBMISSION
            </div>
            <div style={{marginTop: 30}}>
              What's needed:
            </div>
            <FlexBox spaceEvenly style={{minWidth: 320}}>
              <Badge imageSrc={fileTypes}>
                <div><b>File Types:</b> PDF, PNG, Jpeg, 200mb size limit</div>
              </Badge>
              <Badge imageSrc={quality}>
                <div><b>Quality:</b> Please ensure your info is clear</div>
              </Badge>
            </FlexBox>
            <FlexBox centered style={{marginTop: 40, marginBottom: 20}}>
              <Loading loadingMessage="" withCountdown={true}/>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    )
  } else if (uploadPayslipCompleted) {
    return <Redirect to={Routes.incomeComplete}/>
  } else if (uploadPayslipFailed) {
    return (
      <FlexBox flexGrow column>
        <NavRow number={1} text={"Bank Statement or Payslip"} selected={true} last={true}/>
        <FlexBox style={{backgroundColor: "#eeeff0"}} centered column bPadding>
          <FlexBox margin centered style={theme.h2}>Something unexpected has happened...</FlexBox>
          <FlexBox vMargin>{uploadPayslipFailed?.error?.message || "Could not find an error message"}</FlexBox>
          <Button onClick={() => {
            onTryAgainClicked()
          }}>Try Again</Button>
        </FlexBox>
      </FlexBox>
    )
  } else {
    return (
      <FlexBox flexGrow column>
        {/*<NavRow number={1} text={"Photo of ID Card/Book"} selected={true} complete={true}/>*/}
        {/*<NavRow number={2} text={"Selfie without smiling"} selected={true} complete={true}/>*/}
        {/*<NavRow number={3} text={"Selfie with a smile"} selected={true} complete={true}/>*/}
        <NavRow number={1} text={"Bank Statement or Payslip"} selected={true} last={true}/>
        <FlexBox style={{backgroundColor: "#eeeff0"}} centered>
          <FlexBox style={{backgroundColor: "#ffffff", paddingTop: 20}} padding margin column>
            <div style={{paddingTop: 10}}>
              PAYSLIP SUBMISSION
            </div>
            <div style={{marginTop: 30}}>
              What's needed:
            </div>
            <FlexBox spaceEvenly style={{minWidth: 320}}>
              <Badge imageSrc={fileTypes}>
                <div><b>File Types:</b> PDF, PNG, Jpeg, 200mb size limit</div>
              </Badge>
              <Badge imageSrc={quality}>
                <div><b>Quality:</b> Please ensure your info is clear</div>
              </Badge>
            </FlexBox>
            <FlexBox centered style={{marginTop: 40, marginBottom: 20}}>
              <UploadFiles accept={"application/pdf, image/png, image/jpeg, image/jpg"}
                           onFileUploaded={(e) => {
                             if (e.target.files.length) {
                               const formData = new FormData()
                               const file = e.target.files[0]
                               formData.append("file", file, file.name)
                               uploadPayslip({token, formData})
                             }
                           }}>
                <span style={{fontWeight: "bold"}}>Browse files</span>
              </UploadFiles>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    )
  }
}

const Badge = ({imageSrc, children}) => {
  return (
    <FlexBox column style={{width: 120, textAlign: "center"}} centered>
      <FlexBox marginBottom><img src={imageSrc} alt="" width={50} loading="lazy"/></FlexBox>
      <FlexBox style={{fontSize: 14, color: "#9e9e9e"}}>{children}</FlexBox>
    </FlexBox>
  )
}

export default IncomePayslipsUpload
