import {useTheme} from "hooks/useTheme"
import FlexBox from "components/layout/FlexBox"

const RoundButton = ({selected, onClick}) => {
  const {roundButton: style} = useTheme()

  return (
    <FlexBox style={style.base} centered hMargin onClick={onClick}>
      {
        selected && <FlexBox style={style.inner}/>
      }
    </FlexBox>
  )
}

export default RoundButton
