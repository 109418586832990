import React from 'react'
import ReactDOM from 'react-dom/index'
import './index.css'
import { appVersion } from 'AppConfig'
import {BrowserRouter as Router} from "react-router-dom"
import * as Sentry from "@sentry/react"
import axios from "axios"
import AppContextProvider from "api/app/AppContext"
import {StyleRoot} from "radium"
import MobileApp from "MobileApp"
import appApi from "api/app/AppApi"
import identityApi from "api/identity/IdentityApi"
import incomeApi from "api/income/IncomeApi"

if (process.env.NODE_ENV === 'production') {
  import("idlive-face-capture-web")
} else {
  import("idlive-face-capture-web-development")
}

console.log(`App version: ${appVersion}`)

const allowedDomains = [
  'https://sky.prod.sprinthive.com',
  'https://verify.wfs.co.za',
];

// Only enable Sentry on allowed domains and in production
const isSentryEnabled = allowedDomains.includes(window.location.origin) && process.env.NODE_ENV !== 'development';

Sentry.init({
  dsn: "https://a92d924d85b4776507238bd390b58e9e@o4504718797832192.ingest.us.sentry.io/4507141766447104",
  integrations: [
    Sentry.browserTracingIntegration()
  ],
  // Performance Monitoring
  tracesSampleRate: 0.8, //  Capture 80% of the transactions
  enabled: isSentryEnabled,
  environment: process.env.NODE_ENV,
  release: appVersion,
})

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Only capture errors that didn't come from the server as these
    // are otherwise invisible to us
    if (!error.response && error.request) {
      Sentry.captureException(error)
    }
    return Promise.reject(error)
  }
)

// Use the default PUBLIC_URL path if it is present in the path, else assume we are running on root for a custom domain
const contextPath = window.location.pathname.split("/")
const basename = `/${contextPath[1]}` === process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"

ReactDOM.render(
  <React.StrictMode>
    <Router basename={basename}>
      <AppContextProvider appApi={appApi} identityApi={identityApi} incomeApi={incomeApi}>
        <StyleRoot>
          <MobileApp/>
        </StyleRoot>
      </AppContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);