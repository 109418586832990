import {useAppContext} from "api/app/AppContext"
import {AlertOctagon} from "react-feather"
import FlexBox from "components/layout/FlexBox"
import {useTheme} from "hooks/useTheme"
import useOnmount from "hooks/useOnMount"

function IdentityUnexpectedError() {
  const theme = useTheme()
  const {appState, onCaptureLiveFaceFailedToLoad} = useAppContext()
  const token = appState.token

  useOnmount(() => {
    onCaptureLiveFaceFailedToLoad()
  })

  return <FlexBox column centered flexGrow padding margin>
    <AlertOctagon size={72} strokeWidth={1}/> < br/>
    <FlexBox style={theme.h3}>Sorry, it appears your device is not supported. Please try again on another
      device.</FlexBox>
    <FlexBox margin>
      Your token: {token}
    </FlexBox>
  </FlexBox>
}

export default IdentityUnexpectedError