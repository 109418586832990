import produce from "immer"

export const actionTypes = {
  uploadSecureCaptureSelfie: "uploadSecureCaptureSelfie",
  uploadSecureCaptureSelfieCompleted: "uploadSecureCaptureSelfieCompleted",
  uploadSecureCaptureSelfieFailed: "uploadSecureCaptureSelfieFailed",
  onTrySecureCaptureSelfieAgainClicked: "onTrySecureCaptureSelfieAgainClicked",
  onFaceDetectionTextChanged: "onFaceDetectionTextChanged",
  manualCaptureSelected: "manualCaptureSelected",
  autoCaptureSelected: "autoCaptureSelected",
}

export const actionFactory = {
  uploadSecureCaptureSelfie: payload => ({type: actionTypes.uploadSecureCaptureSelfie, payload}),
  uploadSecureCaptureSelfieCompleted: payload => ({type: actionTypes.uploadSecureCaptureSelfieCompleted, payload}),
  uploadSecureCaptureSelfieFailed: payload => ({type: actionTypes.uploadSecureCaptureSelfieFailed, payload}),
  onTrySecureCaptureSelfieAgainClicked: () => ({type: actionTypes.onTrySecureCaptureSelfieAgainClicked}),
  onFaceDetectionTextChanged: payload => ({type: actionTypes.onFaceDetectionTextChanged, payload}),
  manualCaptureSelected: payload => ({type: actionTypes.manualCaptureSelected, payload}),
  autoCaptureSelected: () => ({type: actionTypes.autoCaptureSelected}),
}

export const identityReducer = (state, action) => produce(state, draft => {
  const {type, payload} = action

  console.log("Reducing:", type)

  switch (type) {
    case actionTypes.uploadSecureCaptureSelfie:
      draft.identityState.uploadSecureCaptureSelfieInProgress = true
      draft.identityState.uploadSecureCaptureSelfieFailed = undefined
      break

    case actionTypes.uploadSecureCaptureSelfieCompleted:
      draft.identityState.uploadSecureCaptureSelfieCompleted = true
      draft.identityState.uploadSecureCaptureSelfieFailed = undefined
      draft.identityState.uploadSecureCaptureSelfieInProgress = false
      break

    case actionTypes.uploadSecureCaptureSelfieFailed:
      draft.identityState.uploadSecureCaptureSelfieCompleted = undefined
      draft.identityState.uploadSecureCaptureSelfieFailed = payload
      draft.identityState.uploadSecureCaptureSelfieInProgress = false
      break

    case actionTypes.onTrySecureCaptureSelfieAgainClicked:
      draft.identityState.uploadSecureCaptureSelfieCompleted = undefined
      draft.identityState.uploadSecureCaptureSelfieFailed = undefined
      draft.identityState.faceDetectionErrorCodes = []
      break

    case actionTypes.onFaceDetectionTextChanged:
      draft.identityState.faceDetectionErrorCodes = payload
      break

    case actionTypes.manualCaptureSelected:
      draft.identityState.useManualCapture = true
      draft.identityState.faceDetection = undefined
      draft.cameraInitializationTimedOut = undefined
      break

    case actionTypes.autoCaptureSelected:
      draft.identityState.useManualCapture = false
      draft.identityState.faceDetection = undefined
      draft.cameraInitializationTimedOut = undefined
      break

    default:
    // do nothing for now
  }
})

