import {useAppContext} from "api/app/AppContext"
import React, {useState} from "react"
import FlexBox from "components/layout/FlexBox"
import Button from "components/Button"
import {Routes} from "MobileApp"
import message1Image from "images/message1Image.png"
import message2Image from "images/message2Image.png"
import message3Image from "images/message3Image.png"
import tipImage from "images/light.png"
import message4Image from "images/message4Image.png"
import Radium from "radium"
import {useTheme} from "hooks/useTheme"
import Footer from "components/layout/Footer"
import {MobileLayout} from "components/layout/MobileLayout"
import {Route} from "react-router-dom"
import IncomeBankStatementUpload from "pages/mobile/IncomeBankStatementUpload"
import IncomePayslipsUpload from "pages/mobile/IncomePayslipsUpload"
import IncomeBankStatementOrPayslip from "pages/mobile/IncomeBankStatementOrPayslip"
import IncomeComplete from "pages/mobile/IncomeComplete"
import Loading from "components/Loading"

export default function IncomeModule(props) {
  const {appState} = useAppContext()
  if (appState.tokenState) {
    return (
      <MobileLayout footer={<Footer/>}>
        <Route path={Routes.incomeStart} exact={true} component={Income}/>
        <Route path={Routes.incomeCapture} component={IncomeBankStatementOrPayslip}/>
        <Route path={Routes.incomeBankStatementUpload} component={IncomeBankStatementUpload}/>
        <Route path={Routes.incomePayslipUpload} component={IncomePayslipsUpload}/>
        <Route path={Routes.incomeComplete} component={IncomeComplete}/>
      </MobileLayout>
    )
  } else {
    return (
      <Loading loadingMessage={"Loading token..."} withCountdown={false}/>
    )
  }
}

export function Income({history}) {
  const {appState} = useAppContext()
  const {tenantConfig} = appState
  const messages = [<Message1 tenantName={tenantConfig.name} productName={tenantConfig.productName}/>,
    <Message2/>, <Message3/>, <Message4/>]
  const [selectedStep, setSelectedStep] = useState(0)

  return (
    <FlexBox flexGrow centered column marginBottom>
      {messages[selectedStep]}
      <Button style={{minWidth: 200, marginTop: 20, marginBottom: 10}} onClick={() => {
        const nextStep = selectedStep + 1
        if (nextStep >= messages.length) {
          history.replace(Routes.incomeCapture)
        } else {
          setSelectedStep(nextStep)
        }
      }}>
        Next
      </Button>
      <FlexBox marginTop marginBottom>
        <Dots numberOfDots={messages.length-1} selectedDot={selectedStep}/>
      </FlexBox>
    </FlexBox>
  )
}
const Card = ({image, heading, message, children}) => {
  return (
    <FlexBox column centered padding style={{minHeight: 350}}>
      <FlexBox style={{marginBottom: 30}}><img src={image} height={80} alt="" loading="lazy"/></FlexBox>
      <FlexBox style={{fontSize: 18, fontWeight: 800, marginBottom: 15}}>{heading}</FlexBox>
      {message || children}
    </FlexBox>
  )
}

const Dots = ({numberOfDots, selectedDot}) => {
  const dots = []
  for( let i = 0; i <= numberOfDots; i++) {
    dots.push(<StyledDot key={`nav-dot-${i}`} selected={selectedDot === i}/>)
  }
  return (
    <FlexBox>
      { dots }
    </FlexBox>
  )
}

const StyledDot = Radium(({selected}) => {
  const theme = useTheme()
  return <FlexBox style={[theme.dotStyle.base, selected && theme.dotStyle.selected]}/>
})
const Message1 = ({productName}) => {
  const message = <div style={{maxWidth: 300, textAlign: "center"}}>
    Hey there!
    <p>
      Ready to upload documents for your
      <span style={{fontWeight: 600, whiteSpace: "nowrap"}}> {productName}?</span><br/>
    </p>
    Let's get started!
  </div>
  return (
    <Card image={message1Image} heading="Welcome!" message={message}/>
  )
}
const Message2 = () => {
  const message = <div style={{maxWidth: 300, textAlign: "center"}}>
    You'll submit everything needed right here. Do it in-store, at home or on-the-go!
  </div>
  return (
    <Card image={message2Image} heading="How does it work?" message={message}/>
  )
}

const Message3 = () => {
  const message = <div style={{maxWidth: 300, textAlign: "center"}}>
    We’ll take you through each easy step, helping you submit everything securely.
  </div>
  return (
    <Card image={message3Image} heading="How does it work?" message={message}/>
  )
}

const Message4 = () => {
  const message = <div style={{maxWidth: 300}}>
    <ul>
      {/*<li>Your ID book or ID card</li>*/}
      {/*<li>Mobile phone that can take photos</li>*/}
      <li>Latest 3 months’ bank statements</li>
      <li>Or: latest payslip</li>
    </ul>

    <FlexBox>
      <div><img src={tipImage} width={40} alt=""/></div>
      <div style={{fontSize: 14, marginLeft: 10}}><span style={{fontWeight: 600}}>Tip: </span>
        Download your payslip or bank statements now so they're ready to upload.
      </div>
    </FlexBox>
  </div>
  return (
    <Card image={message4Image} heading="What do I need?" message={message}/>
  )
}

