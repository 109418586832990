import React, {createContext, useCallback, useContext} from "react"
import {useStreamReducer} from "hooks/useStreamReducer"
import {appReducer, actionFactory} from "api/app/AppReducer"
import {actionFactory as identityActionFactory} from "api/identity/IdentityReducer"
import {actionFactory as incomeActionFactory} from "api/income/IncomeReducer"
import appEpics from "api/app/AppEpics"
import {generateTheme} from "theme/theme"
import {getDeviceInfo} from 'lib/deviceInfo'
import useOnmount from "hooks/useOnMount"
import Loading from "components/Loading"

const AppContext = createContext(undefined)
export const useAppContext = () => useContext(AppContext)

function AppContextProvider({children, appApi, identityApi, incomeApi}) {
  const deviceInfo = getDeviceInfo()

  const [appState, dispatch, ready] = useStreamReducer(
    appReducer,
    {
      exchangeTokenLoading: false,
      tenantConfig: undefined,
      apiKey: undefined,
      token: undefined,
      noTokenFound: false,
      tokenState: undefined,
      identityState: {
        faceDetectionErrorCodes: []
      },
      incomeState: {},
      theme: generateTheme(),
      deviceInfo,
      timingMap: {},
    },
    appEpics,
    {appApi, identityApi, incomeApi}
  )

  const onTokenUpdated = useCallback((token) => {
    dispatch(actionFactory.onTokenUpdated({token}))
  }, [dispatch])

  const onNoTokenFound = useCallback(() => {
    dispatch(actionFactory.onNoTokenFound())
  }, [dispatch])

  const onTenantConfigUpdated = useCallback((tenantConfig) => {
    dispatch(actionFactory.onTenantConfigUpdated({tenantConfig}))
  }, [dispatch])

  const uploadBankStatement = useCallback((payload) => {
    dispatch(incomeActionFactory.uploadBankStatement(payload))
  }, [dispatch])

  const uploadPayslip = useCallback((payload) => {
    dispatch(incomeActionFactory.uploadPayslip(payload))
  }, [dispatch])

  const uploadSecureCaptureSelfie = useCallback((payload) => {
    dispatch(identityActionFactory.uploadSecureCaptureSelfie(payload))
  }, [dispatch])

  const onTrySecureCaptureSelfieAgainClicked = useCallback(() => {
    dispatch(identityActionFactory.onTrySecureCaptureSelfieAgainClicked())
  }, [dispatch])

  const onFaceDetectionTextChanged = useCallback((payload) => {
    dispatch(identityActionFactory.onFaceDetectionTextChanged(payload))
  }, [dispatch])

  const onBlockedCameraDetected = useCallback(() => {
    dispatch(actionFactory.blockedCameraDetected({token: appState.token}))
  }, [dispatch, appState.token])

  const onCaptureLiveFaceFailedToLoad = useCallback(() => {
    dispatch(actionFactory.captureLiveFaceFailedToLoad())
  }, [dispatch])

  const onCaptureLiveFaceTimedOut = useCallback(() => {
    dispatch(actionFactory.captureLiveFaceTimedOut())
  }, [dispatch])

  const onUnexpectedCaptureLiveFaceError = useCallback((payload) => {
    dispatch(actionFactory.onUnexpectedCaptureLiveFaceError(payload))
  }, [dispatch])

  const onNoConfigurationProfileFound = useCallback((payload) => {
    dispatch(actionFactory.noConfigurationProfileFound(payload))
  }, [dispatch])

  const onCameraInitializationTimedOut = useCallback(() => {
    dispatch(actionFactory.cameraInitializationTimedOut())
  }, [dispatch])

  const onCameraMounted = useCallback(() => {
    dispatch(actionFactory.cameraMounted())
  }, [dispatch])

  const onCameraUnmounted = useCallback(() => {
    dispatch(actionFactory.cameraUnmounted())
  }, [dispatch])

  const onCameraOpened = useCallback(() => {
    dispatch(actionFactory.cameraOpened())
  }, [dispatch])

  const onResetCameraInitialization = useCallback(() => {
    dispatch(actionFactory.resetCameraInitialization())
  }, [dispatch])

  const onManualCaptureSelected = useCallback(() => {
    dispatch(identityActionFactory.manualCaptureSelected())
  }, [dispatch])

  const onAutoCaptureSelected = useCallback(() => {
    dispatch(identityActionFactory.autoCaptureSelected())
  }, [dispatch])

  useOnmount(() => dispatch(actionFactory.onAppContextMounted()))

  return (
    <AppContext.Provider value={{
      appState: {...appState}, onTokenUpdated, onNoTokenFound, uploadSecureCaptureSelfie,
      onTrySecureCaptureSelfieAgainClicked, onCameraMounted, onCameraUnmounted, onCameraOpened, onFaceDetectionTextChanged, uploadBankStatement,
      uploadPayslip, onTenantConfigUpdated, onBlockedCameraDetected, onCaptureLiveFaceFailedToLoad,
      onCaptureLiveFaceTimedOut, onUnexpectedCaptureLiveFaceError, onNoConfigurationProfileFound,
      onCameraInitializationTimedOut, onResetCameraInitialization, onManualCaptureSelected, onAutoCaptureSelected
    }}>
      {appState.loadApiKeyInProgress === false && appState.tenantConfig && ready
        ? children
        : <Loading loadingMessage={"Loading..."} withCounter={false}/>}
    </AppContext.Provider>
  )
}

export default AppContextProvider
