import React, {useState, useEffect} from "react"
import FlexBox from "components/layout/FlexBox"
import safariIcon from "images/safari.svg"
import operaIcon from "images/opera.svg"
import chromeIcon from "images/chrome.png"
import edgeIcon from "images/edge.svg"
import firefoxIcon from "images/firefox.png"
import samsungIcon from "images/samsung.png"
import {Menu, Settings} from "react-feather"
import {useAppContext} from 'api/app/AppContext'

const Style = {
  container: {
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.5)",
    padding: "20px",
    width: "100%",
    marginBottom: 15,
    backgroundColor: "#fff"
  },
  sectionHeader: {
    fontSize: "24px",
    fontWeight: "bold"
  },
  listItem: {
    padding: "6px 0",
    lineHeight: "1.4",
    fontSize: "16px"
  },
  browserIcon: {
    verticalAlign: "middle",
    width: "24px",
    height: "24px",
    marginRight: "4px"
  },
  note: {
    fontSize: "14px"
  },
  iconStyle: {
    verticalAlign: "middle",
    width: "20px",
    height: "20px"
  },
  tabsContainer: {
    display: "flex",
    borderBottom: "1px solid #ddd",
    overflowX: "auto"
  },
  tab: {
    padding: "5px 10px",
    cursor: "pointer",
    borderBottom: "3px solid transparent",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap"
  },
  activeTab: {
    borderBottom: "3px solid #007bff",
    fontWeight: "bold"
  },
  tabIcon: {
    width: "15px",
    height: "15px",
    marginRight: "4px"
  },
  instructions: {
    fontSize: "16px",
    lineHeight: "1.6"
  }
}

// Helper function to capitalize browser name
const capitalize = (str) => {
  if (!str) return ""
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// Helper function to get browser icon
function getBrowserIcon(browserName) {
  switch (browserName?.toLowerCase()) {
    case "chrome":
      return chromeIcon
    case "firefox":
      return firefoxIcon
    case "opera":
      return operaIcon
    case "safari":
      return safariIcon
    case "edge":
      return edgeIcon
    case "samsung Internet":
      return samsungIcon
    default:
      return null
  }
}

// QuickFix Component for Desktop and Safari
const QuickFix = ({isMobile, browser}) => {
  const isSamsung = browser === "samsung Internet"

  return (
    <FlexBox padding column style={Style.container}>
      <FlexBox centered style={Style.sectionHeader}>
        Quick Fix for {isSamsung ? "Samsung Internet" : isMobile ? "Mobile" : "Desktop"}
      </FlexBox>
      <ol>
        <li style={Style.listItem}>
          {isSamsung ? (
            <>Tap the lock icon <img src={samsungIcon} style={Style.browserIcon} alt="Samsung Internet"/> next to the
              address bar.</>
          ) : (
            <>{isMobile ? "Tap the relevant " : "Click the relevant "}<strong>icon</strong> {isMobile ? "on the left of the address bar." : " on the left of the URL in the address bar."}</>
          )}
        </li>
        <li style={Style.listItem}>
          {isSamsung ? (
            <>In the site info panel, tap <strong>Camera</strong> and select <strong>Allow</strong>.</>
          ) : (
            <>{isMobile ? "Tap it and select " : "Enable the "}<strong>Camera</strong> toggle.</>
          )}
        </li>
        <li style={Style.listItem}>
          Refresh the page and select the "Try Again?" button.
        </li>
      </ol>
      <FlexBox column style={Style.note}>
        <FlexBox>
          <strong>Note:</strong>
        </FlexBox>
        <ul style={{paddingLeft: "20px", marginTop: "8px"}}>
          <li>
            If these steps don't work, try the recommended browser settings below.
          </li>
        </ul>
      </FlexBox>
    </FlexBox>
  )
}

// iOS-specific Instructions Component
const IOSInstructions = ({browser}) => {
  return (
    <FlexBox padding marginBottom centered column style={Style.container}>
      <div style={Style.sectionHeader}>iOS Camera Settings</div>
      <FlexBox marginBottom marginTop column centered>
        <div style={Style.sectionHeader}>Quick Fix</div>
        <ol>
          <li style={Style.listItem}>
            Refresh your browser
          </li>
          <li style={Style.listItem}>
            Tap the "Try Again?" button and <strong>Allow</strong> the camera when prompted again.
          </li>
        </ol>
        <FlexBox column style={Style.note}>
          <FlexBox>
            <strong>Note:</strong>
          </FlexBox>
          <ul style={{paddingLeft: "20px", marginTop: "8px"}}>
            <li>
              If these steps don't work, try the recommended browser settings below.
            </li>
          </ul>
        </FlexBox>
      </FlexBox>
      <ol>
        <li style={Style.listItem}>
          Open <strong>Settings</strong> on your iOS device.
        </li>
        <li style={Style.listItem}>
          Scroll down and tap on <strong>Privacy & Security</strong>.
        </li>
        <li style={Style.listItem}>
          Tap on <strong>Camera</strong>.
        </li>
        <li style={Style.listItem}>
          Find and tap on your current browser{' '}
          {browser &&
            <img src={getBrowserIcon(browser)} style={Style.browserIcon} alt={browser}/>} {capitalize(browser)}.
        </li>
        <li style={Style.listItem}>
          Toggle the camera permission to <strong>Allow</strong>.
        </li>
        <li style={Style.listItem}>
          Return to your browser, refresh the page, and select the "Try Again?" button.
        </li>
      </ol>
    </FlexBox>
  )
}

// Safari-Specific Instructions Component
const SafariInstructions = () => {
  return (
    <FlexBox padding marginBottom centered column style={Style.container}>
      <div style={Style.sectionHeader}>Safari Permissions Reset</div>
      <ol>
        <li style={Style.listItem}>
          Open <img src={safariIcon} style={Style.browserIcon} alt="Safari"/> Safari on your Mac.
        </li>
        <li style={Style.listItem}>
          From the top menu bar, click <strong>Safari</strong> → <strong>Settings</strong>.
        </li>
        <li style={Style.listItem}>
          Click the <strong>Websites</strong> tab at the top.
        </li>
        <li style={Style.listItem}>
          In the left sidebar, click <strong>Camera</strong>.
        </li>
        <li style={Style.listItem}>
          In the right panel, find <code>{window.location.origin}</code>.
        </li>
        <li style={Style.listItem}>
          From the dropdown menu next to the website, select <strong>Allow</strong>.
        </li>
        <li style={Style.listItem}>
          Click Done.
        </li>
        <li style={Style.listItem}>
          Return to Safari, refresh the page, and select the "Try Again?" button.
        </li>
      </ol>
      <FlexBox column style={Style.note}>
        <FlexBox>
          <strong>Note:</strong>
        </FlexBox>
        <ul style={{paddingLeft: "20px", marginTop: "8px"}}>
          <li>
            If camera access is still blocked, check your system settings under <strong>System Preferences → Security &
            Privacy → Privacy → Camera</strong>.
          </li>
          <li>These steps may vary with different Safari and macOS versions.</li>
        </ul>
      </FlexBox>
    </FlexBox>
  )
}

const CameraPermissionReset = () => {
  const {appState} = useAppContext()
  const {deviceInfo} = appState
  const [activeBrowser, setActiveBrowser] = useState("default")
  const [isMobile, setIsMobile] = useState(false)
  const [isIOS, setIsIOS] = useState(false)

  const getBrowserInstructions = (isMobile) => ({
    chrome: (
      <ol>
        <li style={Style.listItem}>
          Open <img src={chromeIcon} style={Style.browserIcon} alt="Google Chrome"/> Google Chrome on
          your {isMobile ? "mobile device" : "computer"}.
        </li>
        <li style={Style.listItem}>
          {isMobile ? "Tap" : "Click"} the menu (three dots) and select <Settings style={Style.iconStyle}/>
          <strong>Settings</strong>.
        </li>
        <li style={Style.listItem}>
          {isMobile ? (
            <>Tap <strong>Site Settings</strong>, then tap <strong>Camera</strong></>
          ) : (
            <>Click <strong>Privacy and Security</strong>, then click <strong>Site Settings</strong></>
          )}
        </li>
        <li style={Style.listItem}>
          {isMobile ? (
            <>Under 'Blocked', tap <code>{window.location.origin}</code></>
          ) : (
            <>Under 'Recent Activity', find <code>{window.location.origin}</code></>
          )}
        </li>
        <li style={Style.listItem}>
          {isMobile ? (
            <>Select <strong>Allow</strong></>
          ) : (
            <>Toggle <strong>Camera</strong> to <strong>Allow</strong></>
          )}
        </li>
        <li style={Style.listItem}>
          Return to the page, refresh, and select the "Try Again?" button.
        </li>
      </ol>
    ),
    firefox: (
      <ol>
        <li style={Style.listItem}>
          Open <img src={firefoxIcon} style={Style.browserIcon} alt="Mozilla Firefox"/> Mozilla Firefox on
          your {isMobile ? "mobile device" : "computer"}.
        </li>
        <li style={Style.listItem}>
          {isMobile ? "Tap" : "Click"} the menu button (three lines) and select <strong>Settings</strong>.
        </li>
        <li style={Style.listItem}>
          {isMobile ? "Tap" : "Click"} <strong>Privacy & Security</strong> and scroll to <strong>Permissions</strong>.
        </li>
        <li style={Style.listItem}>
          {isMobile ? "Tap" : "Click"} <strong>Settings...</strong> next to <strong>Camera</strong> and
          find <code>{window.location.origin}</code>.
        </li>
        <li style={Style.listItem}>
          Set to <strong>Allow</strong> and {isMobile ? "tap" : "click"} <strong>Save Changes</strong>.
        </li>
        <li style={Style.listItem}>
          Return to the page, refresh, and select the "Try Again?" button.
        </li>
      </ol>
    ),
    safari: (
      // Safari specific instructions
      <SafariInstructions/>
    ),
    edge: (
      <ol>
        <li style={Style.listItem}>
          Open <img src={edgeIcon} style={Style.browserIcon} alt="Microsoft Edge"/> Microsoft Edge on
          your {isMobile ? "mobile device" : "computer"}.
        </li>
        <li style={Style.listItem}>
          {isMobile ? "Tap" : "Click"} the <Settings style={Style.iconStyle}/> menu button (three dots) and
          select <strong>Settings</strong>.
        </li>
        <li style={Style.listItem}>
          {isMobile ? (
            <>Tap <strong>Site Settings</strong>, then <strong>Camera</strong></>
          ) : (
            <>Click <strong>Cookies and Site Permissions</strong>, then <strong>All Sites</strong></>
          )}
        </li>
        <li style={Style.listItem}>
          Find <code>{window.location.origin}</code> and set <strong>Camera</strong> to <strong>Allow</strong>.
        </li>
        <li style={Style.listItem}>
          Return to the page, refresh, and select the "Try Again?" button.
        </li>
      </ol>
    ),
    opera: (
      <ol>
        <li style={Style.listItem}>
          Open <img src={operaIcon} style={Style.browserIcon} alt="Opera"/> Opera on
          your {isMobile ? "mobile device" : "computer"}.
        </li>
        <li style={Style.listItem}>
          {isMobile ? (
            <>Tap the User icon in the bottom menu bar</>
          ) : (
            <>Click the menu button (three dots)</>
          )} and select <Settings style={Style.iconStyle}/> <strong>Settings</strong>.
        </li>
        <li style={Style.listItem}>
          {isMobile ? "Tap" : "Click"} <strong>Privacy & security</strong>.
        </li>
        <li style={Style.listItem}>
          to <strong>Allow</strong>.
          {isMobile ? "Tap" : "Click"} <strong>Site settings</strong>, then <strong>Camera</strong>.
        </li>
        {isMobile && (
          <li style={Style.listItem}>
            Find <code>{window.location.origin}</code> then tap <strong>Camera</strong> at the bottom and select to <strong>Allow</strong>.
          </li>
        )}
        <li style={Style.listItem}>
          Return to the page, refresh, and select the "Try Again?" button.
        </li>
      </ol>
    ),
    "samsung Internet": (
      <ol>
        <li style={Style.listItem}>
          Open <img src={samsungIcon} style={Style.browserIcon} alt="Samsung Internet"/> Samsung Internet app on your
          Android device.
        </li>
        <li style={Style.listItem}>
          Tap the menu icon <Menu style={Style.iconStyle}/> (three lines).
        </li>
        <li style={Style.listItem}>
          Tap <strong>Settings</strong>.
        </li>
        <li style={Style.listItem}>
          Tap <strong>Sites and downloads</strong>, then <strong>Site permissions</strong>.
        </li>
        <li style={Style.listItem}>
          Tap <strong>Camera</strong> and find <code>{window.location.origin}</code>.
        </li>
        <li style={Style.listItem}>
          Tap to <strong>Allow</strong>.
        </li>
        <li style={Style.listItem}>
          Return to the page, refresh, and select the "Try Again?" button.
        </li>
      </ol>
    ),
    default: (
      <div style={Style.instructions}>
        <p>
          It seems like your browser is not supported or detected. Please try open this link in one of our supported
          browsers, alternatively, refer to your browser's official documentation on how to enable camera permissions.
        </p>
      </div>
    )
  })
  useEffect(() => {
    if (!deviceInfo) return

    const {appleInfo, browser, mobileDetails, os} = deviceInfo
    const browserLower = browser?.toLowerCase() || ""
    const osInfo = os?.toLowerCase() || ""

    // Determine if the device is mobile and iOS
    const isMobileDevice = !!mobileDetails || osInfo.includes("ios") || osInfo.includes("android")
    const isIOSDevice = osInfo.includes("ios") || appleInfo?.os?.name === "iOS"

    setIsMobile(isMobileDevice)
    setIsIOS(isIOSDevice)

    let browserName = ""

    if (browserLower.includes("crios")) {
      browserName = "chrome"  // Chrome on iOS
    } else if (browserLower.includes("fxios")) {
      browserName = "firefox" // Firefox on iOS
    } else if (browserLower.includes("edge") || browserLower.includes("edg/")) {
      browserName = "edge"
    } else if (browserLower.includes("chrome") && !browserLower.includes("edg")) {
      browserName = "chrome"
    } else if (browserLower.includes("firefox")) {
      browserName = "firefox"
    } else if (browserLower.includes("opera")) {
      browserName = "opera"
    } else if (browserLower.includes("samsung")) {
      browserName = "samsung Internet"
    } else if (appleInfo?.browser?.isSafari || browserLower.includes("safari")) {
      browserName = "safari"
    }

    setActiveBrowser(browserName)
  }, [deviceInfo])

  const browserInstructions = getBrowserInstructions(isMobile)

  return (
    <FlexBox column marginTop>
      {/* Show QuickFix for desktop and Samsung Internet */}
      {(!isMobile || activeBrowser === "samsung Internet") && (
        <QuickFix
          isMobile={isMobile}
          browser={activeBrowser}
        />
      )}

      {/* Show iOS instructions if on iOS device */}
      {isIOS && <IOSInstructions browser={activeBrowser}/>}

      {/* Show browser settings for non-iOS devices */}
      {!isIOS && (
        <FlexBox column style={Style.container}>
          <FlexBox centered marginBottom style={Style.sectionHeader}>
            Browser Settings
          </FlexBox>
          <FlexBox style={Style.tabsContainer}>
            {Object.keys(browserInstructions)
              .filter(browser => !(browser === "samsung Internet" && !isMobile))
              .map((browser) => {
                const browserIcon = getBrowserIcon(browser)
                return (
                  <div
                    key={browser}
                    style={{
                      ...Style.tab,
                      ...(activeBrowser === browser ? Style.activeTab : {})
                    }}
                    onClick={() => setActiveBrowser(browser)}
                  >
                    {browserIcon && <img src={browserIcon} alt={browser} style={Style.tabIcon}/>}
                    {capitalize(browser)}
                  </div>
                )}
              )}
          </FlexBox>
          <div>{browserInstructions[activeBrowser] || browserInstructions.default}</div>
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default CameraPermissionReset