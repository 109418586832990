import FlexBox from "components/layout/FlexBox"
import NavRow from "components/layout/NavRow"
import {CheckCircle} from "react-feather"

const IncomeComplete = () => {

  return (
    <FlexBox flexGrow column>
      {/*<NavRow number={1} text={"Photo of ID Card/Book"} selected={true} complete={true}/>*/}
      {/*<NavRow number={2} text={"Selfie without smiling"} selected={true} complete={true}/>*/}
      {/*<NavRow number={3} text={"Selfie with a smile"} selected={true} complete={true}/>*/}
      <NavRow number={1} text={"Bank Statement or Payslip"} selected={true} last={true} complete={true}/>
      <FlexBox style={{backgroundColor: "#eeeff0"}} centered>
        <FlexBox style={{backgroundColor: "#ffffff", paddingTop: 20, textAlign: "center"}} padding margin column>
          <FlexBox centered>
            <CheckCircle style={{color: "#17BC27"}} size={70}/>
          </FlexBox>
          <div style={{paddingTop: 10}}>
            <b>You're done!</b><br/>Thanks for loading your docs.<br/>You'll hear back from us within 24 hours.<br/>Chat soon!
          </div>
          <div style={{marginTop: 20, marginBottom: 30, fontWeight: "bold"}}>
            You may now close this window
          </div>
        </FlexBox>
        </FlexBox>
    </FlexBox>
  )
}

export default IncomeComplete
