import React from "react";
import PropTypes from "prop-types";
import {FilePlus} from "react-feather";
import {useTheme} from "hooks/useTheme"


const UploadFiles = ({children, accept = "application/pdf, image/png, image/jpeg, image/jpg", onFileUploaded}) => {
  const theme = useTheme()
  const {uploadFilesStyle} = theme
  return (
    <div>
      <input id={"fileSelector"} style={uploadFilesStyle.hideFileInput}
             type="file"
             onChange={onFileUploaded}
             accept={accept}
             multiple/>
      <label style={uploadFilesStyle.button} htmlFor={"fileSelector"}>
        <FilePlus strokeWidth={1.5} size={30} style={uploadFilesStyle.icon}/>
        <div style={uploadFilesStyle.text}>{children}</div>
      </label>
    </div>
  )
};

UploadFiles.propTypes = {
  text: PropTypes.string,
  onFileUploaded: PropTypes.func.isRequired
};

export default UploadFiles;
