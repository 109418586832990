export function uploadSecureCaptureSelfieFailedErrorMapper(error) {
  const {errors} = error || {}
  const errorCodes = errors?.map(({errorCode}) => errorCode) || []
  const errorCode = errorCodes?.length > 0 ? errorCodes[0] : undefined

  switch (errorCode) {
    case "no-face-found":
      return "We could not detect a face in the uploaded image, please try again.";

    case "size-limit-exceeded":
      return "The file was too big, please try again with a file smaller than 6MB";

    case "non-neutral-face":
      return "Remember to not smile when taking a selfie, please try again";

    case "pop-action-not-performed":
      return "We could not detect a smile, please try again";

    case "inconsistent-data-extracted":
      return "We could not extract important data from the document, please upload a clearer image";

    case "unrecognized-document":
      return "We could not recognise the document, please upload a clearer image of a supported identity document";

    case "unreadable-document":
      return "We could not read the document, please try again with a valid file";

    case "retryable-server-error":
      return "There was a temporary problem, please try again later";

    case "non-retryable-server-error":
      return "Sorry, we are experiencing a problem, please try again later with a new submission";

    case "blurred-image":
      return "The image is blurred, please try again";

    case "no-image-found":
      return "We could not find a suitable image within the submission (e.g. within a PDF)";

    case "glasses-not-permitted":
      return "We detected that you are wearing glasses. If you are, please remove them and try again";

    case "self-headshot-trusted-headshot-mismatch":
      return "We could not successfully match the individual in the selfie and identity document, please try again";

    case "self-headshot-pop-headshot-mismatch":
      return "We could not successfully match the individual in the selfie and the \"selfie with a smile\", please try again";

    case "pop-headshot-trusted-headshot-mismatch":
      return "We could not successfully match the individual in the \"selfie with a smile\" and identity document, please" +
        " try again";

    case "face-too-close":
      return "The face in the image is too close to the camera, move further away and try again";

    case "face-close-to-border":
      return "The face in the image is too close to the edge of the photo, center your face and try again";

    case "face-cropped":
      return "The face in the image is not completely in the frame, ensure your face is centered and try again";

    case "face-occluded":
      return "The face in the image is occluded by something, ensure your whole face is visible in the center of the frame and try again";

    case "face-too-small":
      return "The face in the image is too small, move closer to the camera and try again";

    case "face-angle-too-large":
      return "The angle of the face within the image is too large, try again while looking straight at the camera";

    case "multiple-faces-found":
      return "Multiple faces were detected in picture, ensure that only one person is within the capture frame";

    case "non-live-face":
      return "The face was detected as non live. This could be because it is a picture of a picture, someone wearing a mask, a replay or a deepfake";

    case "invalid-input":
      return "The image could not be read, try again with a valid image.";

    case "invalid-image":
      return "The image could not be read, try again with a valid image.";

    case "unreadable-image":
      return "The image could not be read and may be corrupted, try again with a valid image.";

    case "unsupported-content-type":
      return "The submitted file type is not supported, try again with a JPEG, PNG or PDF file";

    default:
      console.log(`Unhandled error code found "${errorCode}"`)
      return "Sorry, we are experiencing a problem, please try again later"
  }
}