import {useAppContext} from "api/app/AppContext"
import TokenNotFoundPage from "pages/mobile/TokenNotFoundPage"
import React from "react"
import {Route, Switch} from "react-router-dom"
import Landing from "pages/mobile/Landing"
import FavIconUpdater from "pages/FavIconUpdater"
import IncomeModule from "pages/mobile/Income"
import IdentityModule from "pages/mobile/Identity"
import TokenInput from "pages/mobile/TokenInput"

export const Routes = {
  home: "/",
  identity: "/identity",
  identityStart: "/identity/start",
  identityCapture: "/identity/capture",
  identityFailed: "/identity/failed",
  identityPermissions: "/identity/permissions",
  identityComplete: "/identity/complete",
  identityTimeout: "/identity/timeout",
  identityManualCapture: "/identity/manual-capture",
  identityError: "/identity/error",
  identityCameraLoadError: "/identity/camera-error",

  income: "/income",
  incomeStart: "/income/start",
  incomeCapture: "/income/capture",
  incomeBankStatementUpload: "/income/bankStatements/upload",
  incomePayslipUpload: "/income/payslips/upload",
  incomeComplete: "/income/complete"
}

function MobileApp() {
  const {appState} = useAppContext()
  const manualTokenFeatureEnabled = appState?.tenantConfig?.features?.manualTokenFeature

  return (
    <>
      <Route path={Routes.home} component={FavIconUpdater}/>
      <Switch>
        <Route path={"/t/:token"} exact={true} component={Landing}/>
        <Route path={"/token/:token"} exact={true} component={Landing}/>

        <Route path={Routes.identity} component={IdentityModule}/>
        <Route path={Routes.income} component={IncomeModule}/>

        {manualTokenFeatureEnabled ? (
          <Route component={TokenInput}/>
        ) : (
          <Route component={TokenNotFoundPage}/>
        )}
      </Switch>
    </>
  )
}

export default MobileApp
