import {useAppContext} from "api/app/AppContext"
import FlexBox from "components/layout/FlexBox"
import Button from "components/Button"
import React, { useState } from "react"
import {Redirect} from "react-router-dom"
import Footer from "components/layout/Footer"
import {MobileLayout} from "components/layout/MobileLayout"

const styles = {
  input: {
    width: 300,
    padding: 5,
    border: '1px solid #ddd',
    borderRadius: 4,
    marginBottom: 5,
    outline: 'none',
  },
  error: {
    color: "red"
  }
}

function TokenInput({history}) {
  const params = new URLSearchParams(window.location.search)
  const t = params.get("t")
  const token = params.get("token")
  const {appState} = useAppContext()
  const [inputToken, setInputToken] = useState(appState.token || "")

  const handleSubmit = () => {
    if (inputToken) {
      history.push(`/t/${inputToken}`)
    }
  }

  console.log(appState.exchangeTokenFailed)

  if (t) {
    return <Redirect to={`/t/${t}`}/>
  } else if (token) {
    return <Redirect to={`/t/${token}`}/>
  } else if (appState.token && !appState.exchangeTokenFailed) {
    return <Redirect to={`/t/${appState.token}`}/>
  } else {
    return (
      <MobileLayout footer={<Footer/>}>
        <FlexBox flexGrow marginTop column centered>
          <h2>Manual Token Input</h2>
          <FlexBox marginTop marginBottom centered column>
            <FlexBox centered margin>
              You've arrived without a token. Please request your unique token from the agent and input below:
            </FlexBox>
            {
              appState.exchangeTokenFailed &&
              <FlexBox centered margin style={styles.error}>
                Sorry, the token you have entered is invalid
              </FlexBox>
            }
          </FlexBox>
          <input
            value={inputToken}
            onChange={(e) => setInputToken(e.target.value)}
            placeholder="Enter your token"
            style={styles.input}
          />
          <FlexBox marginTop centered>
            <Button
              text="Submit Token"
              onClick={handleSubmit}
              disabled={!inputToken}
            />
          </FlexBox>
        </FlexBox>
      </MobileLayout>
    )
  }
}

export default TokenInput