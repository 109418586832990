import FlexBox from "components/layout/FlexBox"
import {useAppContext} from "api/app/AppContext"
import useOnmount from "hooks/useOnMount"
import {Routes} from "MobileApp"
import tipImage from "images/light.png"
import Button from "components/Button"
import faceDetectionErrorMapper from "pages/mobile/faceDetectionErrorMapper"

export default function IdentityCaptureEncryptedSelfieTimeout({history}) {
  const {
    appState,
    onTrySecureCaptureSelfieAgainClicked,
    onCaptureLiveFaceTimedOut,
    onManualCaptureSelected,
    onAutoCaptureSelected
  } = useAppContext()
  const {identityState} = appState || {}
  const {faceDetectionErrorCodes = {}, useManualCapture} = identityState || {}

  useOnmount(() => {
    onCaptureLiveFaceTimedOut()
  })

  const handleRetry = () => {
    onTrySecureCaptureSelfieAgainClicked()
    history.replace(useManualCapture ? Routes.identityManualCapture : Routes.identityCapture)
  }

  const handleSwitchCaptureMode = () => {
    if (useManualCapture) {
      onAutoCaptureSelected?.()
      history.replace(Routes.identityCapture)
    } else {
      onManualCaptureSelected()
      history.replace(Routes.identityManualCapture)
    }
  }

  return (
    <FlexBox flexGrow centered margin padding>
      <FlexBox column centered style={{fontSize: 16, marginLeft: 10, marginRight: 10}}>
        <FlexBox margin>Looks like you are struggling to take the selfie</FlexBox>
        {faceDetectionErrorCodes.length > 1 &&
          <FlexBox style={{fontSize: 16, marginLeft: 20, marginRight: 20}} column>
            <span style={{fontWeight: 600, fontSize: 17}}>Hints:</span>
            <FlexBox>
              <ul style={{fontSize: 16, marginLeft: 10, marginRight: 10}}>
                {
                  faceDetectionErrorCodes.map((e, i) => {
                    return <li key={`face-detection-hint-${i}`}>{faceDetectionErrorMapper(e)}</li>
                  })
                }
              </ul>
            </FlexBox>
          </FlexBox>
        }

        <FlexBox style={{fontSize: 16}} column>
          <span style={{fontWeight: 600, fontSize: 17}}>Remember:</span>
          <FlexBox>
            <FlexBox centered><img src={tipImage} width={40} height={40} alt="Light bulb icon"/></FlexBox>
            <ul>
              <li>Keep a neutral expression - try not to smile!</li>
              <li>Hold the camera steady</li>
              <li>Ensure that your face isn’t in dark shadows in front of a bright background</li>
              <li>Ensure that there are no photos or other people in the background</li>
              <li>Finally, take off your glasses if you have them</li>
            </ul>
          </FlexBox>
        </FlexBox>

        <FlexBox column centered marginTop style={{width: '100%', maxWidth: '250px'}}>
          <FlexBox column style={{width: '100%'}} marginBottom>
            <Button
              onClick={handleRetry}
            >
              Try again
            </Button>
          </FlexBox>
          <FlexBox column style={{width: '100%'}}>
            <Button
              onClick={handleSwitchCaptureMode}
            >
              Switch to {useManualCapture ? 'auto' : 'manual'} capture
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}