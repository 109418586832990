import React, {useCallback, useState} from "react"
import FlexBox from "components/layout/FlexBox"
import CaptureLiveFace from "components/liveFacePlus/CaptureLiveFace"
import {useAppContext} from "api/app/AppContext"
import Loading from "components/Loading"
import {Routes} from "MobileApp"
import {Redirect} from "react-router-dom"
import * as Sentry from "@sentry/react"

export default function IdentityCaptureEncryptedSelfie({ isManual = false }) {
  const [cameraPermissionError, onCameraPermissions] = useState(undefined)
  const [error, onError] = useState(undefined)
  const [timedOut, onTimeout] = useState(undefined)
  const {
    appState,
    uploadSecureCaptureSelfie,
    onFaceDetectionTextChanged,
    onUnexpectedCaptureLiveFaceError
  } = useAppContext()
  const {token, identityState, cameraInitializationTimedOut} = appState || {}
  const {
    uploadSecureCaptureSelfieInProgress,
    uploadSecureCaptureSelfieCompleted,
    uploadSecureCaptureSelfieFailed
  } = identityState || {}

  const onCaptureComplete = useCallback(({encryptedFile}) => {
    uploadSecureCaptureSelfie({token, encryptedPhoto: encryptedFile})
  }, [token, uploadSecureCaptureSelfie])

  if (uploadSecureCaptureSelfieInProgress) {
    return (
      <FlexBox centered flexGrow>
        <Loading loadingMessage={"Uploading..."} withCountdown={false}/>
      </FlexBox>
    )
  } else if (cameraPermissionError) {
    // camera permission has been denied or dismissed
    return <Redirect to={Routes.identityPermissions}/>
  } else if (cameraInitializationTimedOut) {
    return <Redirect to={Routes.identityCameraLoadError}/>
  } else if (error) {
    onUnexpectedCaptureLiveFaceError({message: `An unexpected IDLive Face Plus error has occurred, "${error}"`})
    return <Redirect to={Routes.identityError}/>
  } else if (uploadSecureCaptureSelfieCompleted) {
    return <Redirect to={Routes.identityComplete}/>
  } else if (uploadSecureCaptureSelfieFailed) {
    return <Redirect to={Routes.identityFailed}/>
  } else if (timedOut) {
    return <Redirect to={Routes.identityTimeout}/>
  } else if (!uploadSecureCaptureSelfieCompleted) {
    return (
      <FlexBox flexGrow centered>
        <Sentry.ErrorBoundary fallback={<Redirect to={Routes.identityError}/>}>
          <CaptureLiveFace
            isManual={isManual}
            onCaptureComplete={onCaptureComplete}
            onError={onError}
            onCameraPermissions={onCameraPermissions}
            onTimeout={onTimeout}
            onFaceDetectionTextChanged={onFaceDetectionTextChanged}
          />
        </Sentry.ErrorBoundary>
      </FlexBox>
    )
  }
}