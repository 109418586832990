import React from "react"
import FlexBox from "components/layout/FlexBox"
import Footer from "components/layout/Footer"
import {MobileLayout} from "components/layout/MobileLayout"

function TokenNotFoundPage() {
  return (
    <MobileLayout footer={<Footer/>}>
      <FlexBox flexGrow marginTop column centered>
        <h2>Token Not Found</h2>
        <FlexBox marginTop marginBottom centered>
          Please try opening the link in a different browser, alternatively, please enquire with your agent.
        </FlexBox>
      </FlexBox>
    </MobileLayout>
  )
}

export default TokenNotFoundPage