import React from "react"
import {useAppContext} from "api/app/AppContext"
import useOnmount from "hooks/useOnMount"
import Loading from "components/Loading"
import {Redirect} from "react-router-dom"
import {Routes} from "MobileApp"

function Landing(props) {
  const {appState, onTokenUpdated } = useAppContext()
  const {tenantConfig, tokenState, loadApiKeyInProgress, exchangeTokenFailed} = appState || {}
  // Here we are catering for when the token is passed as an url param
  // http://localhost:3001/doc-upload/t/gLdCdNfb
  // http://localhost:3001/doc-upload/token/gLdCdNfb
  const tokenFromParam = props?.match?.params?.token
  useOnmount(() => onTokenUpdated(tokenFromParam))

  if (exchangeTokenFailed) {
    return <Redirect to={"/"}/>
  } else if (loadApiKeyInProgress || !tenantConfig || !tokenState) {
    return <Loading loadingMessage={"Loading..."} withCounter={false}/>
  } else {
    const {identityVerification} = tokenState || {}
    if (identityVerification) {
      return <Redirect to={Routes.identityStart}/>
    } else {
      return <Redirect to={Routes.incomeStart}/>
    }
  }
}

export default Landing