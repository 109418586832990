import FlexBox from "components/layout/FlexBox"
import {Check} from "react-feather"
import Radium from "radium"
import {useTheme} from "hooks/useTheme"

const NavRow = ({number, text, selected, last, complete}) => {
  return (
    <FlexBox style={{
      borderTop: "solid 1px #E0E0E0",
      paddingLeft: 20,
      borderBottom: last ? "solid 1px #E0E0E0" : null,
      color: "#000000",
      backgroundColor: "#FDFAFA"
    }} flexGrow vPadding vCenter>
      <NumberedDot selected={selected} complete={complete}>{number}</NumberedDot>
      <FlexBox flexGrow spaceBetween rPadding>
        <FlexBox>{text}</FlexBox>
        {complete && <FlexBox style={{color: "#8A8A8A"}}>Complete</FlexBox>}
      </FlexBox>
    </FlexBox>
  )
}

const NumberedDot = Radium(({children, selected, complete}) => {
  const {numberedDot} = useTheme()
  return (
    <FlexBox style={[
      numberedDot.base,
      selected && numberedDot.selected,
      complete && numberedDot.complete
    ]} centered hMargin>
      <FlexBox style={{fontSize: 14}} margin>
        {complete ? <Check size={15}/> : children}
      </FlexBox>
    </FlexBox>
  )
})

export default NavRow
