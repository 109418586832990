import {useAppContext} from "api/app/AppContext"
import Button from "components/Button"
import {Routes} from "MobileApp"
import FlexBox from "components/layout/FlexBox"
import {useTheme} from "hooks/useTheme"
import useOnMount from "hooks/useOnMount"

export default function IdentityCameraLoadError({history}) {
  const theme = useTheme()
  const {
    onCameraInitializationTimedOut,
    onTrySecureCaptureSelfieAgainClicked,
    onResetCameraInitialization
  } = useAppContext()

  useOnMount(() => {
    onCameraInitializationTimedOut()
  })

  const handleRetry = () => {
    onResetCameraInitialization()
    onTrySecureCaptureSelfieAgainClicked()
    history.replace(Routes.identityStart)
  }

  return (
    <FlexBox column centered flexGrow padding margin>
      <FlexBox style={theme.h3}>
        Sorry, it appears your camera is struggling to load. Let's give it another try.
      </FlexBox>
      <FlexBox marginTop>
        <Button onClick={handleRetry}>Try again</Button>
      </FlexBox>
      <FlexBox marginTop>
        If the issue persists, please try again on another device.
      </FlexBox>
    </FlexBox>
  )
}