import axios from "axios"
import {from, of} from "rxjs"
import {mergeMap} from "rxjs/operators"

import {uploadServiceEndpoint} from "AppConfig"

const AppApi = {
  loadApiKey: () => {
    return from(
      axios.get(
        `${window.location.origin}${process.env.PUBLIC_URL}/api/key`,
        {headers: {"Content-Type": "application/json"}}
      )
    ).pipe(mergeMap(({data}) => of({data})))
  },
  exchangeToken: (payload) => {
    const {token, apiKey} = payload
    return from(
      axios.post(
        `${uploadServiceEndpoint}/uploadRequest/validate`,
        { deviceDateTime: new Date() },
        {params: {token}, headers: {apikey: apiKey, "Content-Type": "application/json"}}
      )
    ).pipe(mergeMap(({data}) => of({data})))
  },
  blockedCamera: ({payload, apiKey}) => {
    return from(
        axios.post(
            `${uploadServiceEndpoint}/uploadRequest/blockedCamera`,
            null,
            {params: payload, headers: {apikey: apiKey}}
        )
    ).pipe(mergeMap(({data}) => of({data})))
  },
  logUserJourneyEvent: ({payload, apiKey}) => {
    return from(
      axios.post(
        `${window.location.origin}${process.env.PUBLIC_URL}/api/metric/capture`,
        payload,
        {headers: {apikey: apiKey, "Content-Type": "application/json"}}
      )
    ).pipe(mergeMap(({data}) => of({data})))
  }
}

export default AppApi