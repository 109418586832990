/**
 * The list of error codes can be found here https://docs.idrnd.net/idlivefaceplus/web/usage/detection-errors/
 */
function faceDetectionErrorMapper(errorCode) {
  let errorCodeKey
  if (errorCode === "" || errorCode === undefined) {
    errorCodeKey = undefined
  }
  else {
    errorCodeKey = errorCode
  }
  switch (errorCodeKey) {
    case "FACE_NOT_FOUND":
      return "Make sure you center your face inside the face oval. Slowly bring it closer until we capture the selfie."

    case "TOO_MANY_FACES":
      return "We detected more than one face in the picture, ensure there are no faces in the background (even photos!)"

    case "FACE_ANGLE_TOO_LARGE":
      return "Try look at the camera straight on. The angle of your face was too large"

    case "PROBABILITY_TOO_SMALL":
      return "Ensure there is adequate lighting and your face is in the center of the face oval"

    case "FACE_TOO_SMALL":
      return "Bring your face a bit closer inside the face oval"

    case "FACE_CLOSE_TO_BORDER":
      return "Your face is a bit too close to the borders of the oval. Ensure it is in the center and you are not too close to the camera"

    default:
      return "An unknown error occurred. Please try again"
  }
}

export default faceDetectionErrorMapper