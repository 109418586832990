import React, {useCallback, useEffect, useRef, useState} from "react";
import FlexBox from "components/layout/FlexBox";
import "./CaptureLiveFace.css"
import Loading from "components/Loading";
import {useAppContext} from "api/app/AppContext"
import faceDetectionErrorMapper from "pages/mobile/faceDetectionErrorMapper"
import {Camera} from "react-feather"
import {fromEvent, merge, of} from "rxjs"
import {mergeMap, tap, distinctUntilChanged, filter} from "rxjs/operators"

const Style = {
  faceHintError: {
    margin: 5,
    padding: 5,
    color: "#C13426",
    fontWeight: "bold",
    backgroundColor: "#f2f2f2"
  },
  faceHintNoError: {
    margin: 5,
    padding: 5,
    color: "#5f9f63",
    fontWeight: "bold",
    backgroundColor: "#f2f2f2"
  },
  cameraButton: {
    cursor: 'pointer',
    padding: '16px',
    backgroundColor: '#000000',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
    transition: 'transform 0.2s ease'
  }
}

function LiveFaceInput({
                         camera, onCameraReady, onCameraOpening, onBeforeCapture, onFaceDetectionTextChanged,
                         onCaptureComplete, onError, onCameraPermissions, onClose, isManual = false,
                       }) {
  const [faceHint, setFaceHint] = useState("")
  const {appState, onCameraOpened, onCameraMounted, onCameraUnmounted} = useAppContext()
  const faceHintFlag = appState?.tenantConfig?.features?.faceCaptureHints

  const handleManualCapture = useCallback(() => {
    if (camera.current) {
      camera.current.takePhoto()
    }
  }, [camera])

  useEffect(() => {
    const cameraModule = camera.current
    if (cameraModule !== undefined) {
      onCameraMounted()
    }
    console.log("Camera mounted", camera.current !== undefined)

    const faceDetection$ = fromEvent(cameraModule, "faceDetection")

    const faceHints$ = faceDetection$.pipe(
      mergeMap((event) => {
        const hasErrors = event.detail[0].errors.length > 0
        return of(hasErrors ? faceDetectionErrorMapper(event.detail[0].errors[0]) : "Perfect!")
      }),
      distinctUntilChanged(),
      tap((faceHint) => setFaceHint(faceHint)),
    )

    const faceDetectionChanged$ = faceDetection$.pipe(
      filter(event => event.detail[0].errors.length > 0),
      mergeMap((event) => {
        return of(event.detail[0].errors.filter(code => code !== "FACE_NOT_FOUND"))
      }),
      filter(errors => errors.length > 0),
      tap((errors) => onFaceDetectionTextChanged(errors)),
    )

    const initialize$ = fromEvent(cameraModule, "initialize")
      .pipe(
        tap(() => {
          console.log("initialize")
          camera.current && camera.current.openCamera && camera.current.openCamera()
        })
      )

    const beforeOpen$ = fromEvent(cameraModule, "beforeOpen")
      .pipe(
        tap(() => {
          console.log("beforeOpen")
          onCameraOpening && onCameraOpening()
        })
      )

    const open$ = fromEvent(cameraModule, "open")
      .pipe(
        tap(() => {
          console.log("open")
          onCameraReady && onCameraReady()
          onCameraOpened()
        })
      )

    const beforeCapture$ = fromEvent(cameraModule, "beforeCapture")
      .pipe(
        tap(() => {
          console.log("beforeCapture")
          onBeforeCapture && onBeforeCapture()
        })
      )

    const capture$ = fromEvent(cameraModule, "capture")
      .pipe(
        tap((event) => {
          console.log("capture")
          onCaptureComplete && onCaptureComplete(event.detail[0])
        })
      )

    const error$ = fromEvent(cameraModule, "error")
      .pipe(
        tap((event) => {
          console.log("error: ", event.detail)
          const message = event?.detail[0]?.message
          if (message && (message.match(/.*permission.*/i) || message.match(/.*not allowed by the user agent.*/i))) {
            onCameraPermissions && onCameraPermissions(event.detail)
          } else {
            onError && onError(message)
          }
        })
      )

    const close$ = fromEvent(cameraModule, "close")
      .pipe(
        tap(() => {
          console.log("close")
          setFaceHint("")
          onClose && onClose()
        })
      )

    const subs = merge(
      initialize$,
      beforeOpen$,
      open$,
      beforeCapture$,
      capture$,
      faceHints$,
      faceDetectionChanged$,
      error$,
      close$
    ).subscribe()

    return () => {
      subs && subs.unsubscribe()
      onCameraUnmounted()
    }
  }, [camera, onError, onCameraPermissions, onFaceDetectionTextChanged, onCaptureComplete, onBeforeCapture,
    onCameraOpening, onCameraReady, onClose, onCameraMounted, onCameraUnmounted, onCameraOpened])

  return (
    <FlexBox column centered>
      <FlexBox className={"camera"}>
        <idlive-face-capture
          style={{width: "100%", height: "100%"}}
          className="id-camera"
          payload_size="small"
          {... isManual ? { auto_capture_disabled: true } : {}}
          ref={camera}
        />
      </FlexBox>
      {faceHintFlag && (
        <FlexBox className={"faceHintContainer"} column={isManual} centered margin padding>
          <FlexBox
            centered
            style={faceHint !== "Perfect!" ? Style.faceHintError : Style.faceHintNoError}
            flexWrap
          >
            {faceHint}
          </FlexBox>
          {isManual && (
            <div
              onClick={handleManualCapture}
              style={Style.cameraButton}
            >
              <Camera size={32} color="white"/>
            </div>
          )}
        </FlexBox>
      )}
    </FlexBox>
  )
}

function CaptureLiveFaceDetail({
                                 onCaptureComplete,
                                 onFaceDetectionTextChanged,
                                 onError,
                                 onCameraPermissions,
                                 onTimeout,
                                 isManual = false
                               }) {
  const {appState, onCameraMounted, onCameraInitializationTimedOut} = useAppContext()
  const [cameraReady, setCameraReady] = useState(false)

  const onCameraReady = useCallback(() => {
    console.log("onCameraReady triggered")
    setCameraReady(true)
  }, [setCameraReady])

  const onInitializeTimeout = useCallback(() => {
    console.log("Camera initialization timeout triggered")
    onCameraInitializationTimedOut()
  }, [onCameraInitializationTimedOut])

  const display = cameraReady ? "flex" : "none"

  const camera = useRef()
  const openCamera = useCallback(() => {
    camera.current && camera.current.openCamera && camera.current.openCamera()
  }, [])

  useEffect(() => {
    if (appState.cameraMounted) {
      console.log("camera is mounted so we are going to open it")
      openCamera()
    }
  }, [appState.cameraMounted, openCamera]);

  useEffect(() => {
    let timeoutId

    if (!cameraReady) {
      timeoutId = setTimeout(onInitializeTimeout, 45000)
    } else {
      onTimeout && (timeoutId = setTimeout(() => {
        onTimeout(true)
      }, 15000))
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [cameraReady, onTimeout, onInitializeTimeout])

  return (
    <FlexBox column centered>
      <FlexBox style={{minHeight: 350}} centered>
        {
          !cameraReady &&
          <FlexBox><Loading noLayout={true} loadingMessage={"Opening camera"} withCountdown={false}/></FlexBox>
        }
        <FlexBox style={{display}}>
          <LiveFaceInput {...{
            camera,
            onCameraMounted,
            onCaptureComplete,
            onCameraReady,
            onFaceDetectionTextChanged,
            onError,
            onCameraPermissions,
            isManual
          }}/>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}
export default CaptureLiveFaceDetail
