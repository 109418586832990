import FlexBox from "components/layout/FlexBox"
import {ErrorPage} from "components/error/ErrorPage"
import {useAppContext} from "api/app/AppContext"
import {Routes} from "MobileApp"
import {uploadSecureCaptureSelfieFailedErrorMapper} from "pages/mobile/IdentityErrorMapper"

export default function IdentityCaptureEncryptedSelfieFailed({history}) {
  const {
    appState,
    onTrySecureCaptureSelfieAgainClicked,
    onManualCaptureSelected,
    onAutoCaptureSelected
  } = useAppContext()

  const {identityState} = appState || {}
  const {
    uploadSecureCaptureSelfieFailed,
    useManualCapture
  } = identityState || {}

  let message = uploadSecureCaptureSelfieFailedErrorMapper(
    uploadSecureCaptureSelfieFailed?.response?.data?.error)

  const handleRetry = () => {
    onTrySecureCaptureSelfieAgainClicked()
    history.push(useManualCapture ? Routes.identityManualCapture : Routes.identityCapture)
  }

  const handleSwitchCaptureMode = () => {
    onTrySecureCaptureSelfieAgainClicked()

    if (useManualCapture) {
      onAutoCaptureSelected?.()
      history.push(Routes.identityCapture)
    } else {
      onManualCaptureSelected()
      history.push(Routes.identityManualCapture)
    }
  }

  return (
    <FlexBox flexGrow centered column marginBottom>
      <ErrorPage
        message={message}
        onRetry={handleRetry}
        useManualCapture={useManualCapture}
        onSwitchCaptureMode={handleSwitchCaptureMode}
      />
    </FlexBox>
  )
}