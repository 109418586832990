import React from "react";
import {AlertTriangle, Frown} from "react-feather";
import Button from "../Button";
import FlexBox from "components/layout/FlexBox";

const potentialMessages = [
  {message: "Oops something went wrong..."},
  {message: "Looks like he a hit a tree Jim..."},
];

const potentialIcons = [
  {icon: <Frown size={72} strokeWidth={1}/>},
  {icon: <AlertTriangle size={72} strokeWidth={1}/>},
];

const Style = {
  container: {
  },
  message: {
    fontSize: 18,
    fontWeight: 400,
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    textAlign: 'center'
  },
  buttonContainer: {
    marginTop: 20,
    gap: 10,
    width: '100%'
  }
};

export const ErrorPage = ({
                            message = potentialMessages[Math.floor(Math.random() * potentialMessages.length)].message,
                            icon = potentialIcons[Math.floor(Math.random() * potentialIcons.length)].icon,
                            onRetry,
                            useManualCapture,
                            onSwitchCaptureMode
                          }) => {
  return (
    <FlexBox style={Style.container} column centered>
      {icon}
      <FlexBox style={Style.message}>
        {message}
      </FlexBox>
      {onRetry && (
        <FlexBox style={Style.buttonContainer} column centered>
          <Button
            style={{width: '100%'}}
            text="Try again"
            onClick={onRetry}
          />
          {onSwitchCaptureMode && (
            <Button
              style={{width: '100%'}}
              text={useManualCapture ? "Try auto capture" : "Try manual capture"}
              onClick={onSwitchCaptureMode}
            />
          )}
        </FlexBox>
      )}
    </FlexBox>
  )
}