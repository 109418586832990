import FlexBox from "components/layout/FlexBox"
import NavRow from "components/layout/NavRow"
import {CheckCircle} from "react-feather"

function IdentityCaptureEncryptedSelfieComplete() {
  return (
    <FlexBox flexGrow column>
      <NavRow number={1} text={"Capture Selfie Complete!"} selected={true} last={true} complete={true}/>
      <FlexBox style={{backgroundColor: "#eeeff0"}} centered>
        <FlexBox style={{backgroundColor: "#ffffff", paddingTop: 20, textAlign: "center"}} padding margin column>
          <FlexBox centered>
            <CheckCircle style={{color: "#17BC27"}} size={70}/>
          </FlexBox>
          <div style={{paddingTop: 10}}>
            <b>Complete</b><br/>Thank you!
          </div>
          <div style={{marginTop: 20, marginBottom: 30, fontWeight: "bold"}}>
            You may now close this window
          </div>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default IdentityCaptureEncryptedSelfieComplete