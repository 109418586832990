import FlexBox from 'components/layout/FlexBox'
import {useAppContext} from 'api/app/AppContext'
import useOnMount from 'hooks/useOnMount'
import {Routes} from "MobileApp"
import Button from "components/Button"
import IdentityCaptureEncryptedSelfiePermissionsReset from "pages/mobile/IdentityCaptureEncryptedSelfiePermissionsReset"
import {useCallback, useEffect, useState} from "react"

const getStyles = (windowWidth) => ({
  wrapper: {
    width: '100%',
    maxWidth: windowWidth <= 800 ? '350px' : '800px',
    margin: '0 auto',
  },
  messageContainer: {
    width: '100%',
    maxWidth: '100%',
    wordWrap: 'break-word',
    padding: windowWidth <= 800 ? '12px' : '20px',
  },
});

function IdentityCaptureEncryptedSelfiePermissions({history}) {
  const {onBlockedCameraDetected} = useAppContext()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const Style = getStyles(windowWidth)

  // For responsive screen sizing - Desktop / Mobile
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useOnMount(onBlockedCameraDetected)

  const backToStart = useCallback(() => {
    history.replace(Routes.identity)
  }, [history])

  return (
    <FlexBox column centered style={Style.wrapper}>
      <FlexBox column style={Style.messageContainer}>
        <FlexBox>
          To verify your identity, this website needs permission to use your camera to take a picture of you.
          Unfortunately your current settings do not allow camera access for this website.
        </FlexBox>
        <IdentityCaptureEncryptedSelfiePermissionsReset />
        <FlexBox centered>
          <Button onClick={backToStart}>Try again?</Button>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default IdentityCaptureEncryptedSelfiePermissions