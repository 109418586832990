import omfLogo from "images/Logos/om-logo.png"
import sprintHiveLogo from "images/Logos/SprintHive-Logo-grey.svg"
import finChoiceLogo from "images/Logos/finchoice-logo.png"
import wfsLogo from "images/Logos/wfs.png"
import rcsLogo from "images/Logos/rcs-logo.png"
import wfsFav from "images/Logos/wfs-fav.png"
import sanlamLogo from "images/Logos/sanlam-logo.png"
import assupolLogo from "images/Logos/assupol-logo.png"

export const tenantConfigs = [
  {
    tenantCode: "sh",
    name: "SprintHive",
    productName: "Credit Card",
    domain: ["demo.pte.sprinthive.com", "dev00.sprinthive.tech", "localhost"],
    logo: sprintHiveLogo,
    pallet: {
      colour1: "#000",
      colour2: "#FFF",
      colour3: "#17BC27"
    },
    messages: {
      "outbound-sales": {
        title: "SprintHive Application",
        message: "To continue with your SprintHive application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "product-application": {
        title: "SprintHive Application",
        message: "To continue with your SprintHive application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "SprintHive Enquiry",
        message: "To assist you with your SprintHive enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  { //Sanlam Sky/Lifecheq UAT
    tenantCode: "sky",
    name: "Sanlam",
    productName: "Sanlam Policy",
    domain: ["sky-uat.pte.sprinthive.com"],
    logo: sanlamLogo,
    pallet: {
      colour1: "#0072c6",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "product-application": {
        title: "Sanlam Application",
        message: "To continue with your Sanlam policy application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "policy-application": {
        title: "Sanlam Application",
        message: "To continue with your Sanlam policy application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "Sanlam Enquiry",
        message: "To assist you with your Sanlam enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  { //Sanlam Sky/Lifecheq PROD
    tenantCode: "sky",
    name: "Sanlam",
    productName: "Sanlam Policy",
    domain: ["sky.prod.sprinthive.com"],
    logo: sanlamLogo,
    pallet: {
      colour1: "#0072c6",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "product-application": {
        title: "Sanlam Application",
        message: "To continue with your Sanlam policy application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "policy-application": {
        title: "Sanlam Application",
        message: "To continue with your Sanlam policy application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "Sanlam Enquiry",
        message: "To assist you with your Sanlam enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  { //Assupol UAT
    tenantCode: "asp",
    name: "Assupol",
    productName: "Assupol Policy",
    domain: ["asp-uat.pte.sprinthive.com", "qa00.sprinthive.tech"],
    logo: assupolLogo,
    pallet: {
      colour1: "#00338d",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "product-application": {
        title: "Assupol Application",
        message: "To continue with your Assupol policy application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "policy-application": {
        title: "Assupol Application",
        message: "To continue with your Assupol policy application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "Assupol Enquiry",
        message: "To assist you with your Assupol enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  { //Assupol PROD
    tenantCode: "asp",
    name: "Assupol",
    productName: "Assupol Policy",
    domain: ["asp.prod.sprinthive.com"],
    logo: assupolLogo,
    pallet: {
      colour1: "#00338d",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "product-application": {
        title: "Assupol Application",
        message: "To continue with your Assupol policy application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "policy-application": {
        title: "Assupol Application",
        message: "To continue with your Assupol policy application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "Assupol Enquiry",
        message: "To assist you with your Assupol enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  {
    tenantCode: "omf",
    name: "Old Mutual",
    productName: "Old Mutual Personal Loans",
    domain: ["sandbox-3.uat.sprinthive.com"],
    logo: omfLogo,
    pallet: {
      colour1: "#129c6e",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "product-application": {
        title: "Old Mutual Application",
        message: "To continue with your Old Mutual Personal Loan application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "Old Mutual Enquiry",
        message: "To assist you with your Old Mutual enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  {
    tenantCode: "rcs",
    name: "RCS",
    productName: "RCS Loan",
    domain: ["rcs.uat.sprinthive.com", "rcs.prod.sprinthive.com"],
    logo: rcsLogo,
    pallet: {
      colour1: "#EA5206",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "product-application": {
        title: "RCS Loan Application",
        message: "To continue with your RCS Loan application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "RCS Enquiry",
        message: "To assist you with your RCS enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  { //WFS non-PROD
    tenantCode: "wfs",
    name: "Woolies",
    productName: "WFS Cards",
    domain: ["wfs.uat.sprinthive.com"],
    logo: wfsLogo,
    favIcon: wfsFav,
    privacyPolicyUrl: "https://www.woolworths.co.za/corporate/cmp205289",
    pallet: {
      colour1: "#000",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "outbound-sales": {
        title: "Woolies Application",
        message: "To continue with your Woolies application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "outbound-sales-enquiry": {
        title: "Woolies Application",
        message: "To continue with your Woolies application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "product-application": {
        title: "Woolies Application",
        message: "To continue with your Woolies application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "Woolies Customer Service",
        message: "To assist you with your Woolies enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  { //WFS PROD
    tenantCode: "wfs",
    name: "Woolies",
    productName: "WFS Cards",
    domain: ["wfs.prod.sprinthive.com", "verify.wfs.co.za"],
    logo: wfsLogo,
    favIcon: wfsFav,
    privacyPolicyUrl: "https://www.woolworths.co.za/corporate/cmp205289",
    pallet: {
      colour1: "#000",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "outbound-sales": {
        title: "Woolies Application",
        message: "To continue with your Woolies application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "outbound-sales-enquiry": {
        title: "Woolies Application",
        message: "To continue with your Woolies application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "product-application": {
        title: "Woolies Application",
        message: "To continue with your Woolies application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "Woolies Customer Service",
        message: "To assist you with your Woolies enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  },
  {
    tenantCode: "finChoice",
    name: "FinChoice",
    productName: "FinChoice Personal Loan",
    domain: ["finchoice.prod.sprinthive.com"],
    logo: finChoiceLogo,
    pallet: {
      colour1: "#0a1f8f",
      colour2: "#FFF",
      colour3: "#FFF"
    },
    messages: {
      "product-application": {
        title: "FinChoice Loan Application",
        message: "To continue with your FinChoice Personal Loan application, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      },
      "customer-inbound-enquiry": {
        title: "FinChoice Enquiry",
        message: "To assist you with your FinChoice enquiry, please verify your identity by taking a photo.\n\nThis photo will only be used for identity verification purposes.",
        buttonText: "VERIFY USING CAMERA"
      }
    },
    features: {
      faceCaptureHints: true,
      manualTokenFeature: true
    }
  }
]

export const findTenantByDomain = domain => {
  return tenantConfigs.find(tenant => tenant.domain.includes(domain))
}

export const findTenantByTenantCode = code => {
  return tenantConfigs.find(tenant => tenant.tenantCode === code)
}
