import {useCallback, useState} from "react"
import FlexBox from "components/layout/FlexBox"
import NavRow from "components/layout/NavRow"
import Button from "components/Button"
import RoundButton from "components/RoundButton"
import {Routes} from "MobileApp"

const IncomeBankStatementOrPayslip = ({history}) => {
  const [type, setType] = useState("bankStatements")

  const setTypeBankStatement = useCallback(() => {
    setType("bankStatements")
  }, [setType])

  const setTypePayslips = useCallback(() => {
    setType("payslips")
  }, [setType])

  return (
    <FlexBox flexGrow column>
      <NavRow number={1} text={"Bank Statement or Payslip"} selected={true} last={true}/>
      <FlexBox style={{backgroundColor: "#eeeff0"}} centered flexGrow>
        <FlexBox style={{backgroundColor: "#ffffff"}} padding margin column>
          <FlexBox style={{fontSize: 18, marginBottom: 30, textAlign: "center"}} centered >
            Which documents do you want to upload? Choose one:
          </FlexBox>
          <FlexBox style={{backgroundColor: "#f8f8f8", padding: 20}} column>
            <FlexBox vCenter>
              <RoundButton selected={type === "bankStatements"} onClick={setTypeBankStatement}/>
              <FlexBox column marginLeft onClick={setTypeBankStatement}>
                <FlexBox style={{fontWeight: 600}}>Bank Statements</FlexBox>
                <FlexBox style={{fontSize: 14}}>
                  (latest 3 months')
                </FlexBox>
              </FlexBox>

            </FlexBox>
            <FlexBox centered style={{marginTop: 30, marginBottom: 30}}>
              OR
            </FlexBox>
            <FlexBox vCenter>
              <RoundButton selected={type === "payslips"} onClick={setTypePayslips}/>
              <FlexBox column marginLeft onClick={setTypePayslips}>
                <FlexBox style={{fontWeight: 600}} >
                  Payslips
                </FlexBox>
                <FlexBox style={{fontSize: 14}} marginBottom>
                  (Latest payslip)
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox centered style={{marginTop: 40, marginBottom: 20}}>
              <Button style={{minWidth: 150}} onClick={() => {
                history.push(type === "bankStatements"
                  ? Routes.incomeBankStatementUpload
                  : Routes.incomePayslipUpload
                )
              }}>Next</Button>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  )
}

export default IncomeBankStatementOrPayslip
